import type { SupabaseClient } from '@supabase/supabase-js';
import xss from 'xss';

export function getInitials(name: string | null | undefined): string {
	if (name === undefined || name === null) {
		return '?';
	}

	return name
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase())
		.join('');
}

export function descriptionPreview(description: string) {
	return description.length > 100 ? description.slice(0, 100) + '...' : description;
}

export function getPageSettings(searchParams: URLSearchParams): { page: number; limit: number } {
	let page = Number(searchParams.get('page')) || 0;
	let limit = Number(searchParams.get('limit')) || 10;
	return { page, limit };
}

export function getLatLng(searchParams: URLSearchParams): {
	lat: number | null;
	lng: number | null;
} {
	let lat = searchParams.get('lat') ? Number(searchParams.get('lat')) : null;
	let lng = searchParams.get('lng') ? Number(searchParams.get('lng')) : null;
	return { lat, lng };
}

export function getPublicURL(supabase: SupabaseClient, path: string): string | undefined {
	return supabase.storage.from('listings').getPublicUrl(path, {
		// transform: {
		// 	width: 400,
		// 	height: 400
		// }
	}).data.publicUrl;
}

export function sanitize(content: string): string {
	return xss(content);
}
